import axios from "axios";

const request = axios.create({
    baseURL: '',
})
request.interceptors.response.use(response => {
    return response;
}, error => {
    if(error.response.status === 401 || error.response.status === 404)
    {
        return error.response;
    }
})

export default request;