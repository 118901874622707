<template>
<div>
  <div>
    <el-button type="primary" @click="insertUser">新增用户</el-button>
    <el-button type="info" @click="getUsers">刷新</el-button>
    <el-button type="danger" @click="logout">注销登录</el-button>
  </div>
  <el-input v-model="search" placeholder="输入关键字搜索，可模糊匹配姓名、用户号、手机号、登录号" @input="getUsers" style="margin: 20px 0"/>
  <el-dialog :visible.sync="dialogVisible" :title="dialogTitle" width="50%">
    <el-form  ref="formRef" :model="form" :rules="rules">
      <el-form-item prop="userName" label="姓名">
        <el-input v-model="form.userName" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="userSex" label="性别">
        <el-input v-model="form.userSex" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="userNumber" label="用户号">
        <el-input v-model="form.userNumber" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="userTel" label="手机号">
        <el-input v-model="form.userTel" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="userRemark" label="备注">
        <el-input v-model="form.userRemark" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="userLoginNumber" label="登录号">
        <el-input v-model="form.userLoginNumber" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="userPwd" label="密码">
        <el-input v-model="form.userPwd" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm">保 存</el-button>
    </span>
  </el-dialog>
  <el-table
      :data="users"
      border
      style="width: 100%">
    <el-table-column
        prop="userId"
        label="编号"
        width="100">
    </el-table-column>
    <el-table-column
        prop="userName"
        label="姓名"
        width="180">
    </el-table-column>
    <el-table-column
        prop="userSex"
        label="性别">
    </el-table-column>
    <el-table-column
        prop="userNumber"
        label="用户号">
    </el-table-column>
    <el-table-column
        prop="userTel"
        label="手机号">
    </el-table-column>
    <el-table-column
        prop="userRemark"
        label="备注">
    </el-table-column>
    <el-table-column
        prop="userLoginNumber"
        label="登录号">
    </el-table-column>
    <el-table-column
        fixed="left"
        label="操作"
        width="100">
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="editUser(scope.row)">编辑</el-button>
        <el-button type="text" size="small" @click="deleteUser(scope.row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
</div>
</template>

<script>
import request from "@/api/request";
export default {
  name: 'ManagementCenter',
  data() {
    return {
      users: [],
      search: '',
      dialogVisible: false,
      isEdit : false,
      dialogTitle: '新增',
      form: {
        userId: '',
        userName: '',
        userSex: '',
        userNumber: '',
        userTel: '',
        userRemark: '',
        userLoginNumber: '',
        userPwd: ''
      },
      formLabelWidth: '120px',
      rules:{
        userName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        userSex: [
          { required: true, message: '请输入性别', trigger: 'blur' }
        ],
        userNumber: [
          { required: true, message: '请输入用户号', trigger: 'blur' }
        ],
        userTel: [
          { required: true, message: '请输入手机号', trigger: 'blur' }
        ],
        userLoginNumber: [
          { required: true, message: '请输入登录号', trigger: 'blur' }
        ],
        userPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    logout(){
      localStorage.clear();
      this.$router.push('/login');
      this.$message({
        message: '注销成功',
        type: 'success'
      });
    },
    getUsers() {
      request.get(`/api/users?search=${this.search}`).then(res => {
        this.users = res.data;
      })
    },
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          if (this.isEdit) {
            request.put(`/api/users/${this.form.userId}`, this.form).then(res => {
              if (res.status === 200) {
                this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                this.getUsers();
              }
            })
          } else {
            request.post('/api/users', this.form).then(res => {
              if (res.status === 201) {
                this.$message({
                  message: '保存成功',
                  type: 'success'
                });
                this.getUsers();
              }
            })
          }
          this.dialogVisible = false
        } else {
          this.$message({
            message: '请完成表单填写',
            type: 'warning'
          });
          return false;
        }
      });
    },
    insertUser() {
      this.dialogTitle = '新增';
      this.dialogVisible = true;
      this.isEdit = false;
      this.form = {}
    },
    editUser(row){
      request.get('/api/users/'+row.userId).then(res => {
        if(res.status === 200)
        {
          this.form = res.data;
          this.dialogTitle = '编辑';
          this.dialogVisible = true;
          this.isEdit = true;
          this.form = res.data;
        }
        else if(res.status === 404)
        {
          this.$message({
            message: res.data,
            type: 'warning'
          });
          this.getUsers();
        }
      })
    },
    deleteUser(row){
      request.delete('/api/users/'+row.userId).then(res => {
        if (res.status === 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
          this.getUsers();
        }
      })
    }
  },
  mounted() {
    this.getUsers();
  }
}
</script>


<style scoped>
  a {
    margin: 10px;
  }
</style>