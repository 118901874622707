<template>
  <div id="login">
    <el-form
        ref="formRef"
        :label-position="labelPosition"
        label-width="80px"
        :model="form"
        :rules="rules">
      <el-form-item label="用户名" prop="userLoginNumber">
        <el-input v-model="form.userLoginNumber" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="userPwd">
        <el-input v-model="form.userPwd" autocomplete="off" type="password"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="login">登录</el-button>
      </el-form-item>
      <el-form-item>
        <div id="tip">默认账号：admin，默认密码：123456</div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import request from "@/api/request";
export default {
  name: 'UserLogin',
  data() {
    return {
      labelPosition: 'top',
      form: {
        userLoginNumber: '',
        userPwd: '',
      },
      rules: {
        userLoginNumber: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
        ],
        userPwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ]
      }
    }
  },
  methods: {
    login() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          request.post('/api/users/login', this.form).then(res => {
            if (res.status === 200) {
              localStorage.setItem('sessionId', res.data.sessionId);
              localStorage.setItem('user', JSON.stringify(res.data));
              this.$router.push('/management-center');
            }
            else if (res.data.status === 401)
            {
              alert('用户名或密码有误！');
            }
            else
            {
              console.log('我是else')
            }
          })
        } else {
          this.$message({
            message: '请完成表单填写',
            type: 'warning'
          });
          return false;
        }
      })
    }
  }
}
</script>

<style scoped>
  #login {
    width: 400px;
    margin: 200px auto 0;
  }
  #tip {
    font-size: 12px;
    color: #999;
  }
</style>