import Router from 'vue-router'
import ManagementCenter from '@/components/ManagementCenter'
import UserLogin from '@/components/UserLogin.vue'

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: '/management-center'
        },
        {
            path: '/management-center',
            name: 'ManagementCenter',
            component: ManagementCenter
        },
        {
            path: '/login',
            name: 'UserLogin',
            component: UserLogin
        }
    ]
})

router.beforeEach((to, from, next) => {
    let sessionId = localStorage.getItem('sessionId');
    if (to.path === '/login') {
        localStorage.clear();
        next();
    }
    if (sessionId) {
        next();
    } else {
        next('/login');
    }
})

export default router